/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const adminService = {
  _url: `${process.env.REACT_APP_BASE_API_URL}/admin`,
  GetSessionRequests(searchQuery, refetch) {
    const [requests, setRequests] = useState({
      totalItems: 0,
      items: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getSessionRequests(searchQuery))
        .then(res => {
          setRequests(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterrequests,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);
    return {
      requests_loading: status === STATUS.LOADING,
      requests_error: status === STATUS.ERROR ? status : '',
      requests_data: requests,
    };
  },
  GetAdmins(searchQuery, refetch) {
    const [admins, setAdmins] = useState({
      totalItems: 0,
      admins: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdmins(searchQuery))
        .then(res => {
          setAdmins(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterAdmins,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);
    return {
      admins_loading: status === STATUS.LOADING,
      admins_error: status === STATUS.ERROR ? status : '',
      admins_data: admins,
    };
  },

  GetPermissions(searchQuery, refetch) {
    const [permissions, setPermissions] = useState({
      totalItems: 0,
      permissions: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getPermissions(searchQuery))
        .then(res => {
          if (searchQuery.parentOnly) {
            setPermissions(() => ({
              permissions: res.permissionStatus,
              totalItems: res.length,
            }));
          } else setPermissions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      searchQuery?.parentOnly,
      searchQuery?.filterPermission,
      refetch,
    ]);
    return {
      permissions_loading: status === STATUS.LOADING,
      permissions_error: status === STATUS.ERROR ? status : '',
      permissions_data: permissions,
    };
  },

  GetRoles(searchQuery, refetch) {
    const [roles, setRoles] = useState({
      totalItems: 0,
      roles: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getRoles(searchQuery))
        .then(res => {
          setRoles(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterRoles,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);
    return {
      roles_loading: status === STATUS.LOADING,
      roles_error: status === STATUS.ERROR ? status : '',
      roles_data: roles,
    };
  },

  GetServices(searchQuery, refetch) {
    const [services, setServices] = useState({
      totalItems: 0,
      services: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getServices(searchQuery))
        .then(res => {
          setServices(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterServices,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      searchQuery?.filterText,
      refetch,
    ]);
    return {
      services_loading: status === STATUS.LOADING,
      services_error: status === STATUS.ERROR ? status : '',
      services_data: services,
    };
  },
  GetServicesWithPackages(searchQuery, refetch) {
    const [services, setServices] = useState({
      totalItems: 0,
      services: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getServicesWithPackages(searchQuery))
        .then(res => {
          setServices(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterServices,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      searchQuery?.filterText,
      refetch,
    ]);
    return {
      services_loading: status === STATUS.LOADING,
      services_error: status === STATUS.ERROR ? status : '',
      services_data: services,
    };
  },

  GetServiceProviders(searchQuery, refetch) {
    const [serviceProviders, setServiceProviders] = useState({
      totalItems: 0,
      serviceProviders: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getServiceProviders(searchQuery))
        .then(res => {
          setServiceProviders(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterProviders,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);
    return {
      serviceProviders_loading: status === STATUS.LOADING,
      serviceProviders_error: status === STATUS.ERROR ? status : '',
      serviceProviders_data: serviceProviders,
    };
  },

  GetResellers(searchQuery, refetch) {
    const [resellers, setResellers] = useState({
      totalItems: 0,
      resellers: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getResellers(searchQuery))
        .then(res => {
          setResellers(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterResellers,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      searchQuery?.resellerId,
      searchQuery?.roleType,
      refetch,
    ]);
    return {
      resellers_loading: status === STATUS.LOADING,
      resellers_error: status === STATUS.ERROR ? status : '',
      resellers_data: resellers,
    };
  },

  GetPricelist(searchQuery, refetch) {
    const [pricelist, setPricelist] = useState({
      totalItems: 0,
      pricelist: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getPricelist(searchQuery))
        .then(res => {
          setPricelist(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);

    return {
      pricelist_loading: status === STATUS.LOADING,
      pricelist_error: status === STATUS.ERROR ? status : '',
      pricelist_data: pricelist,
    };
  },
  GetPricelistReseller(searchQuery, refetch) {
    const [pricelist, setPricelist] = useState({
      totalItems: 0,
      pricelist: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getPricelistReseller(searchQuery))
        .then(res => {
          setPricelist(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);

    return {
      pricelist_loading: status === STATUS.LOADING,
      pricelist_error: status === STATUS.ERROR ? status : '',
      pricelist_data: pricelist,
    };
  },

  GetAdminDashboard(searchQuery, refetch) {
    const [dashboard, setDashboard] = useState({
      totalItems: 0,
      dashboard: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdminDashboard(searchQuery))
        .then(res => {
          setDashboard(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.filterResellers,
      searchQuery?.filterOrders,
      searchQuery?.resellerId,
      refetch,
    ]);

    return {
      dashboard_loading: status === STATUS.LOADING,
      dashboard_error: status === STATUS.ERROR ? status : '',
      dashboard_data: dashboard,
    };
  },
  GetPremiumResellerMonthlyView(searchQuery, refetch) {
    const [monthlyData, setMonthlyData] = useState({
      totalItems: 0,
      dashboard: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdminDashboardMonthlyView(searchQuery))
        .then(res => {
          setMonthlyData(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.searchText,
      searchQuery?.filterResellers,
      searchQuery?.filterOrders,
      refetch,
    ]);

    return {
      monthly_loading: status === STATUS.LOADING,
      monthly_error: status === STATUS.ERROR ? status : '',
      monthly_data: monthlyData,
    };
  },

  GetSessionCalendar(searchQuery, refetch) {
    const [sessionCalendar, setSessionCalendar] = useState({
      totalItems: 0,
      sessionCalendar: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);

    useEffect(() => {
      if (!searchQuery?.startDate || !searchQuery?.endDate) return;
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getSessionCalendar(searchQuery))
        .then(res => {
          setSessionCalendar(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.perPage,
      searchQuery?.page,
      searchQuery?.forCalender,
      refetch,
    ]);

    return {
      sessionCalendar_loading: status === STATUS.LOADING,
      sessionCalendar_error: status === STATUS.ERROR ? status : '',
      sessionCalendar_data: sessionCalendar,
    };
  },

  GetJobHouseUsers(searchQuery, refetch) {
    const [jbusers, setJbusers] = useState({
      totalItems: 0,
      jbusers: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);

    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getJobHouseUsers(searchQuery))
        .then(res => {
          setJbusers(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery?.searchText, searchQuery?.startDate, searchQuery?.endDate, refetch]);

    return {
      jbusers_loading: status === STATUS.LOADING,
      jbusers_error: status === STATUS.ERROR ? status : '',
      jbusers_data: jbusers,
    };
  },

  GetOrderHistory(searchQuery, refetch) {
    const [history, setHistory] = useState({
      totalItems: 0,
      history: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getOrderHistory(searchQuery))
        .then(res => {
          setHistory(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery?.page, searchQuery?.pageSize, searchQuery?.orderId, refetch]);

    return {
      history_loading: status === STATUS.LOADING,
      history_error: status === STATUS.ERROR ? status : '',
      history_data: history,
    };
  },
  GetComissionOrders(searchQuery, refetch) {
    const [orders, setOrders] = useState({
      totalItems: 0,
      orders: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.SUCCESS);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getComissionOrders(searchQuery))
        .then(res => {
          setOrders(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      refetch,
    ]);

    return {
      orders_loading: status === STATUS.LOADING,
      orders_error: status === STATUS.ERROR ? status : '',
      orders_data: orders,
    };
  },

  GetResellerServices(searchQuery, refetch) {
    const [service, setService] = useState({
      totalItems: 0,
      items: [],
    });
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getResellerServices(searchQuery))
        .then(res => {
          setService(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.page,
      searchQuery?.searchText,
      searchQuery?.filterText,
      searchQuery?.resellerId,
      refetch,
    ]);
    return {
      service_loading: status === STATUS.LOADING,
      service_error: status === STATUS.ERROR ? status : '',
      service_data: service,
    };
  },

  GetResellerOrders(searchQuery, refetch) {
    const [dashboard, setDashboard] = useState({
      totalItems: 0,
      items: [],
    });
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getResellerOrders(searchQuery))
        .then(res => {
          setDashboard(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.page,
      searchQuery?.searchText,
      searchQuery?.filterText,
      refetch,
    ]);
    return {
      dashboard_loading: status === STATUS.LOADING,
      dashboard_error: status === STATUS.ERROR ? status : '',
      dashboard_data: dashboard,
    };
  },

  GetStudents(searchQuery, refetch) {
    const [students, setStudents] = useState({
      totalItems: 0,
      students: [],
    });
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getStudents(searchQuery))
        .then(res => {
          setStudents(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery.searchText,
      searchQuery.startDate,
      searchQuery.endDate,
      searchQuery.page,
      searchQuery.pageSize,
      searchQuery.resellerId,
    ]);
    return {
      students_loading: status === STATUS.LOADING,
      students_error: status === STATUS.ERROR ? status : '',
      students_data: students,
    };
  },
  GetServicePackages(searchQuery, refetch) {
    const [packages, setPackages] = useState({
      totalItems: 0,
      items: [],
    });
    const [status, setStatus] = useState(STATUS.SUCCESS);
    const { cancellablePromise } = useCancellablePromise();
    useEffect(() => {
      cancellablePromise(this.getServicePackages(searchQuery))
        .then(res => {
          setPackages(res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery.searchText,
      searchQuery.startDate,
      searchQuery.endDate,
      searchQuery.page,
      searchQuery.pageSize,
      searchQuery.resellerId,
    ]);
    return {
      packages_loading: status === STATUS.LOADING,
      packages_error: status === STATUS.ERROR ? status : '',
      packages_data: packages,
    };
  },
  // apis
  async getAdmins({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterAdmins = '',
    startDate = '',
    endDate = '',
    getAll = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/admins?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterAdmins=${filterAdmins}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        admins: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createAdmin(payload) {
    let res = await Fetch.post(`${this._url}/create-admin`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateAdmin(id, payload) {
    let res = await Fetch.put(`${this._url}/update-admin/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteAdmin(id) {
    let res = await Fetch.delete(`${this._url}/delete-admin/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getPermissions({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    parentOnly = false,
    filterPermission = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-permission?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterText=${filterPermission}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&parentOnly=${parentOnly}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      if (parentOnly) {
        return {
          permissionStatus: res.items.map(({ can }) => ({
            label: can.split('.')[0],
            value: can.split('.')[0],
          })),
        };
      }
      return {
        permissions: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createPermission(payload) {
    let res = await Fetch.post(`${this._url}/create-permission`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updatePermission(id, payload) {
    let res = await Fetch.put(`${this._url}/update-permission/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deletePermission(id) {
    let res = await Fetch.delete(`${this._url}/delete-permission/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async restorePermissions() {
    let res = await Fetch.post(`${this._url}/restore-permissions`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getRoles({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterRoles = '',
    startDate = '',
    endDate = '',
    getAll = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/get-all-role?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterRoles=${filterRoles}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        roles: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createRole(payload) {
    let res = await Fetch.post(`${this._url}/create-role`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateRole(id, payload) {
    let res = await Fetch.put(`${this._url}/update-role/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteRole(id) {
    let res = await Fetch.delete(`${this._url}/delete-role/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async rolesFiltersOptions({ getAll = true }) {
    let res = await Fetch.get(`${this._url}/get-all-role?getAll=${getAll}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        options: res.items.map(({ type }) => ({ label: type, value: type })),
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getServices({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterServices = '',
    startDate = '',
    endDate = '',
    getAll = false,
    filterText = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/services?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterServices=${filterServices}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        services: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getServicesWithPackages({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterServices = '',
    startDate = '',
    endDate = '',
    getAll = false,
    filterText = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/services-with-packages?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterServices=${filterServices}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        services: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getServiceByProvider(resellerId, id) {
    let res = await Fetch.get(`${this._url}/service/${id}/${resellerId}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getServicesByProvider(providerId) {
    let res = await Fetch.get(`${this._url}/service/${providerId}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createService(payload) {
    let res = await Fetch.post(`${this._url}/create-service`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateService(id, payload) {
    let res = await Fetch.put(`${this._url}/update-service/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteService(id) {
    let res = await Fetch.delete(`${this._url}/delete-service/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.service;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getServiceProviders({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterProviders = '',
    startDate = '',
    endDate = '',
    getAll = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/service-providers?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterProviders=${filterProviders}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        serviceProviders: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async serviceProvidersFiltersOptions({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterProviders = '',
    startDate = '',
    endDate = '',
    getAll = true,
  }) {
    let res = await Fetch.get(
      `${this._url}/service-providers?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterProviders=${filterProviders}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return {
        options: res?.items?.map(({ _id, providerName }) => ({ label: providerName, value: _id })),
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createServiceProvider(payload) {
    let res = await Fetch.post(`${this._url}/create-service-provider`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createServicePackage(payload) {
    let res = await Fetch.post(`${this._url}/create-service-package`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getServicePackages({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterPackages = '',
    startDate = '',
    endDate = '',
    getAll = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/service-packages?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterPackages=${filterPackages}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        items: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateServicePackage(id, payload) {
    let res = await Fetch.put(`${this._url}/update-service-package/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async deleteServicePackage(id) {
    let res = await Fetch.delete(`${this._url}/delete-service-package/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateServiceProvider(id, payload) {
    let res = await Fetch.put(`${this._url}/update-service-provider/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteServiceProvider(id) {
    let res = await Fetch.delete(`${this._url}/delete-service-provider/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getResellers({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterResellers = '',
    startDate = '',
    endDate = '',
    getAll = false,
    resellerId = '',
    roleType = null,
  }) {
    let res = await Fetch.get(
      `${this._url}/resellers?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterResellers=${filterResellers}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&resellerId=${resellerId}&roleType=${roleType}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        resellers: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createReseller(payload) {
    let res = await Fetch.post(`${this._url}/create-reseller`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getReseller(id) {
    let res = await Fetch.get(`${this._url}/reseller/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateReseller(id, payload) {
    let res = await Fetch.put(`${this._url}/update-reseller/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteReseller(id) {
    let res = await Fetch.delete(`${this._url}/delete-reseller/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res.reseller;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getPricelist({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterText = '',
    startDate = '',
    endDate = '',
    getAll = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/pricelist?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterText=${filterText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        pricelist: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getPricelistReseller({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    resellerId = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/pricelist/${resellerId}?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterText=${filterText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        pricelist: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createPricelist(payload) {
    let res = await Fetch.post(`${this._url}/create-pricelist`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updatePricelist(id, payload) {
    let res = await Fetch.put(`${this._url}/update-pricelist/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deletePricelist(id) {
    let res = await Fetch.delete(`${this._url}/delete-pricelist/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getAdminDashboard({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterResellers = '',
    filterOrders = '',
    resellerId = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/dashboard?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterResellers=${filterResellers}&filterOrders=${filterOrders}&resellerId=${resellerId}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        dashboard: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAdminDashboardMonthlyView({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterResellers = '',
    filterOrders = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/dashboard-monthly?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterResellers=${filterResellers}&filterOrders=${filterOrders}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        dashboard: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async verifyOrder(id, status) {
    let res = await Fetch.put(`${this._url}/verify-order/${id}`, { status });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async verifyBulkOrders(payload) {
    let res = await Fetch.put(`${this._url}/verify-bulk-orders`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getSessionCalendar({ startDate = '', endDate = '', page = 1, perPage = 10, forCalender = false }) {
    let res = await Fetch.get(
      `${this._url}/session-calendar?startDate=${startDate}&endDate=${endDate}&page=${page}&perPage=${perPage}&forCalender=${forCalender}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        sessionCalendar: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createSession(payload) {
    let res = await Fetch.post(`${this._url}/create-session`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateSession(id, payload) {
    let res = await Fetch.put(`${this._url}/update-session/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteSession(id) {
    let res = await Fetch.delete(`${this._url}/delete-session/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getJobHouseUsers({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '', getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/jobhouseusers?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        jbusers: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createJobHouseUser(payload) {
    let res = await Fetch.post(`${this._url}/create-jbuser`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateJobHouseUser(id, payload) {
    let res = await Fetch.put(`${this._url}/update-jbuser/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteJobHouseUser(id) {
    let res = await Fetch.delete(`${this._url}/delete-jbuser/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getOrderHistory({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    orderId = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/order-history/${orderId}?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        history: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },

  async getComissionOrders({ page = 1, pageSize = 10, searchText = '', startDate = '', endDate = '', getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/comission-orders?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        orders: res?.items,
        totalItems: res?.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getResellerServices({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    filterText = '',
    getAll = false,
    resellerId = '',
  }) {
    const res = await Fetch.get(
      `${this._url}/reseller-services/${resellerId}?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterText=${filterText}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },

  async getResellerOrders({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    resellerId = '',
    filterText = '',
  }) {
    const res = await Fetch.get(
      `${this._url}/orders/${resellerId}?page=${page}&perPage=${pageSize}&searchText=${searchText}&startDate=${startDate}&endDate=${endDate}&filterText=${filterText}`,
    );
    if (res.status >= 200 && res.status < 300) {
      return res.json();
    }
    const { message } = await res.json();
    throw Error(message ?? 'Something went wrong');
  },

  async createStudent(resellerId, payload) {
    let res = await Fetch.post(`${this._url}/create-student/${resellerId}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateStudent(id, payload) {
    let res = await Fetch.put(`${this._url}/update-student/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getStudents({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    resellerId = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/students?page=${page}&perPage=${pageSize}&searchText=${searchText}&resellerId=${resellerId}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        students: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateSessionRequest(id, payload) {
    let res = await Fetch.put(`${this._url}/session-request/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getSessionRequests({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterAdmins = '',
    startDate = '',
    endDate = '',
    getAll = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/session-requests?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterAdmins=${filterAdmins}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        requests: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};

export default adminService;
